import React from "react"
import { Link } from "gatsby"
import Products from "../../components/peaksuiteProducts"

const PeaksuiteCompass = ({ handleSubmit }) => (
  <div className="page-content pt-0" style={{ background: "#f8f8f8" }}>
    <section className="component pt-4 pb-0">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12 peakSuite-product">
            <iframe
              src="https://drive.google.com/file/d/1o4CK58txkuwc4puOcQvezvgMAJTRx-7i/preview"
              frameBorder="0"
              width="60%"
              height="450"
              className="float-left mr-3 mb-3"
              style={{ boxShadow: "0 8px 10px rgba(0, 0, 0, 0.37)" }}
              title="compass"
            />
            <p>
              <span className="compass-color">peakSUITE Compass </span>
              is a custom-built mapping tool within Quickbase that provides your
              team a full overview of the location-based processes your business
              handles.
            </p>
            <p>
              View topics like PROJECTS, CUSTOMERS, or SITES in full-page map
              format with multiple options for searching and categorizing.
              Color-code locations to your preferences and organize them by
              type, so you can view them by category. Roll over all sites listed
              on your map for further detail and open the related records. Sort
              projects/events/etc. and{" "}
              <span className="compass-color">fully customize</span> the sorting
              and grouping options depending on what information best fits your
              business needs.
            </p>
            <p>
              peakSUITE Compass is easy to use from the start. All controls for
              navigating and zooming match the desktop version of Google Maps,
              letting your team go live in Quick Base fast!{" "}
              <span className="compass-color">Without Extra Training</span>
            </p>
            <p>
              Enhance the Quickbase Mapping tool with peakSUITE Compass. Collate
              locations into usable formats,{" "}
              <span className="compass-color">making it easy</span> to build
              location information into a cohesive structure without manually
              creating documents and reports. And recategorize your addresses
              dynamically for better insight. peakSUITE Compass makes it easy to
              create useful location-based data in Quickbase without extensive
              knowledge of report building and sorting methods.{" "}
              <span className="compass-color">Easily organize</span> your
              projects,events, sites, or other data. And automatically add
              locations or adjust positioning when necessary. Project or
              Customer locations can be tagged on the map with images of your
              choice to reflect customer or project type. You can also choose
              different markers for your own locations, to differentiate
              buildings that offer certain services or identify to users which
              addresses should be applied as meeting places depending on Project
              or Client type! Add{" "}
              <span className="compass-color">peakSUITE Compass </span>to your
              Quickbase apps and enjoy a custom interface for location data that
              provides better visibility, helping you optimize your business
              processes today!
            </p>
            <div className="text-center">
              <Link
                to="/contact/"
                onClick={handleSubmit}
                className="btn btn-primary btn-arrow compass-bg"
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
        <div className="row peaksuite-products mt-5">
          <div className="col-lg-2 col-md-3 col-12 align-self-center  prod-order-2">
            {" "}
            <div className="return-btn-compass">
              <Link
                to="/peaksuite/"
                className="btn btn-primary-outline px-3"
                style={{
                  color: "#15a896",
                  border: "1px solid #15a896",
                  fontSize: "18px",
                }}
              >
                <i className="fa fa-caret-left mr-2" />
                Return
              </Link>
            </div>
          </div>
          <div className="col-lg-10 col-md-9 col-12 prod-order-1">
            <Products />
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default PeaksuiteCompass
